import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	public get goBackCustomAction(): Function {
		return this._goBackCustomAction;
	}
	public set goBackCustomAction(action: Function) {
		this._goBackCustomAction = action;
	}

	private _history: string[] = [];
	private _goBackCustomAction: Function;

	constructor(private router: Router) {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.goBackCustomAction = null;
			this._history.push(event.urlAfterRedirects);
		});
	}

	public goBack(): void {
		if (this.goBackCustomAction) {
			this.goBackCustomAction();
			this.goBackCustomAction = null;
		} else if (this._history.length > 1) {
			this._history.pop();
			const previousUrl = this._history.pop();
			if (previousUrl) {
				this.router.navigateByUrl(previousUrl);
			}
		}
	}

	public isCurrentPath(path: string): boolean {
		return !!this._history.length && this._history[this._history.length - 1].includes(path);
	}
}
